module directives {
    export module master {
        interface IProductCategoryScope extends ng.IScope {    
            enforceWidth: number;
            Reason: string;
            selectedProductCategoryList: Array<interfaces.master.IProductCategory>; // us this if isSingleSelect = false
            selectedProductCategory:   interfaces.master.IProductCategory;   // use this if isSingSelect = true   
            ngDisabled: boolean;
            isSingleSelect: boolean;
            showProductCategory(): void;
            removeProductCategory(): void;
        }

        export class productCategoryDirective implements ng.IDirective {
            template = `            
            <div class="row col-md-12">
                    <div class="col-md-9 panel panel-default rounded" style="min-height: 30px;" ng-if="!this.isSingleSelect">
                        <ul style="list-style: none;">
                            <li ng-repeat="productCategory in this.selectedProductCategoryList">
                            <span class="bg-info">
                                {{productCategory.Code}}-{{productCategory.Description}}
                                <span  ng-click="this.selectedProductCategoryList.splice($index,1)"><i class="fa fa-times"></i></span>
                            </span>
                            </li>
                        </ul>
                    </div>
                    <div class="panel panel-default rounded pull-left" style="height: 36px;min-width:250px; width:85%; vertical-align: middle; display:table;" ng-if="this.isSingleSelect && this.selectedProductCategory">
                            <span ng-if="this.selectedProductCategory"  style="display:table-cell;vertical-align:middle; padding-left: 10px;">
                                {{this.selectedProductCategory.Code}}-{{this.selectedProductCategory.Description}}
                                <span  ng-click="removeProductCategory()"><i class="fa fa-times"></i></span>
                            </span>
                    </div>                    
                    <div class="pull-right">
                        <button type="button" class="btn btn-info" ng-click="showProductCategory()" ng-disabled="ngDisabled"><i class="fa fa-ellipsis-h"></i></button>
                    </div>
            <div>
                    `;
            scope = {              
                ngDisabled: "=",     
                selectedProductCategoryList : "=?",
                selectedProductCategory : "=",
                isSingleSelect: "=?"
            }

            Reason: string;
            isSingleSelect: boolean;

            treeData= [];
            list: Array<interfaces.master.IProductCategory>;
            selectedProductCategoryList = new Array<interfaces.master.IProductCategory>();
            selectedProductCategory : interfaces.master.IProductCategory;
            apiPCList: uiGrid.IGridApi;
            selectedRows = [];

            constructor(private generalService: interfaces.applicationcore.IGeneralService,
                public $uibModal: ng.ui.bootstrap.IModalService, private productCategoryService: interfaces.master.IProductCategoryService, private $state: ng.ui.IStateService
            ) {  }

            link = ($scope: IProductCategoryScope, $element: ng.IAugmentedJQuery, $state) => {
                this.Reason = "my 3 reasons";
                $scope.Reason = this.Reason;
                this.isSingleSelect = $scope.isSingleSelect;

                $scope.selectedProductCategoryList = this.selectedProductCategoryList;
               // $scope.selectedProductCategory = this.selectedProductCategory;

                $scope.removeProductCategory = () => {
                    $scope.selectedProductCategory = null;
                }
                
                $scope.showProductCategory = () => {
                        this.$uibModal.open({
                            animation: true,
                            template: `
                            <div class="modal-content" >
                                <form name="productCategory">
                                    <div class="gts-padding">
                                        <fieldset id="productCat">
                                        <legend>
                                            Product Category 
                                        </legend>                                            
                                                <div class="row vertical-align">
                                                    <div class="col-md-12"> 
                                                            <div ui-grid="gvwProductCategoryTreeView" ui-grid-tree-view ui-grid-selection ></div>
                                                    </div>                                               
                                                </div>                                     
                                                <div class="gts-padding pull-right">                                               
                                                        <button type="button" class="btn btn-primary"  ng-click="ok()">Submit</button>
                                                        <button type="button" class="btn btn-info" ng-click="cancel()">Cancel</button>
                                                </div>
                                        </fieldset>
                                    </div>
                                </form>
                            </div>
                            `,
                            controller: ($scope, $uibModalInstance: ng.ui.bootstrap.IModalInstanceService, Reason, selectedProductCategorylist,selectedProductCategory) => {
                                $scope.ok = () => {
                                    $uibModalInstance.close(this.selectedProductCategory);
                                }

                                $scope.cancel = () => {
                                    this.selectedProductCategoryList.length = 0;
                                    $uibModalInstance.close(this.selectedProductCategory);
                                }

                                $scope.registerGridApi = (gridApi: uiGrid.IGridApi) => {
                                    $scope.apiPCList = gridApi;
                                    $scope.apiPCList.treeBase.on.rowExpanded($scope, function (row) {
                                    });
                                }

                                $scope.loadData = (entityId, userId, parentCategoryId) => {
                                    this.treeData = [];
                                    this.productCategoryService.getProductCategoryByParent(entityId, userId, parentCategoryId).query((data: Array<interfaces.master.IProductCategory>) => {

                                        this.list = data;

                                        for (let item of this.list) {
                                            this.treeData.push({ Id: item.Id, Code: item.Code, Description: item.Description, $$treeLevel: 0 });
                                        }

                                        $scope.gvwProductCategoryTreeView.data = this.treeData;
                                    });
                                }

                                $scope.rowSelected = (row) => {

                                    if (this.isSingleSelect)
                                    {
                                        if (!row.isSelected)
                                        {
                                            this.selectedProductCategory = null;
                                        }
                                        else
                                        {
                                            this.selectedProductCategory = <interfaces.master.IProductCategory> { Id: row.entity.Id, Code: row.entity.Code, Description: row.entity.Description, ChildrenCount: 1 }
                                        }                                    
                                    }
                                    else
                                    {
                                        if (!row.isSelected)
                                        {
                                            for (let productCategory of this.selectedProductCategoryList) {
                                                if (productCategory.Id === row.entity.Id)
                                                    this.selectedProductCategoryList.splice(this.selectedProductCategoryList.indexOf(productCategory), 1);
                                            }
                                        }
                                        else
                                        {
                                            var Exists : number  = 0;
                                            for (let productCategory of this.selectedProductCategoryList) {
                                                if (productCategory.Id === row.entity.Id) {
                                                    Exists = 1;
                                                }
                                            }
                                            if (Exists === 0)
                                            {
                                                this.selectedProductCategoryList.push(<interfaces.master.IProductCategory>{ Id: row.entity.Id, Code: row.entity.Code, Description: row.entity.Description, ChildrenCount: 1 });
                                            }
                                        }
                                    }

                                    
                                }

                                $scope.loadChildren = (entityId, userId, row) => {
                                    this.productCategoryService.getProductCategoryByParent(0, 0, row.entity.Id).query((data: Array<interfaces.master.IProductCategory>) => {
                                        this.list = data;
                                        for (let item of this.list) {
                                            $scope.gvwProductCategoryTreeView.data.splice($scope.gridApi.grid.renderContainers.body.visibleRowCache.indexOf(row) + 1, 0,
                                                { Id: item.Id, Code: item.Code, Description: item.Description, $$treeLevel: row.treeLevel + 1 });
                                        }
                                    });
                                }

                                $scope.gvwProductCategoryTreeView = {
                                    enableSorting: true,
                                    enableFiltering: false,
                                    multiSelect: !this.isSingleSelect,
                                    showTreeExpandNoChildren: true,
                                    columnDefs: [
                                        { name: 'Id', width: '30%', visible: false },
                                        { name: 'Code', width: '20%' },
                                        { name: 'Description', width: '65%' }
                                    ],
                                    onRegisterApi: function (gridApi) {
                                        $scope.gridApi = gridApi;
                                        $scope.loadData(0, 0, null);
                                        $scope.gridApi.treeBase.on.rowExpanded($scope, function (row) {
                                            $scope.loadChildren(1, 1, row);
                                        });

                                        $scope.gridApi.selection.on.rowSelectionChanged($scope, function (row) {
                                            $scope.rowSelected(row);
                                        });
                                    }
                                };
                            },
                            size: "md",
                            resolve: {
                                Reason: function () { return this.Reason },
                                selectedProductCategorylist: function () { return this.selectedProductCategorylist },
                                selectedProductCategory: function () { return this.selectedProductCategory }
                            }
                        }).result.then(function (selectedItem) {
                            $scope.selectedProductCategory = selectedItem;
                        });
                };
            }

            static factory(): ng.IDirectiveFactory {
                const directive = (generalService, $uibModal, productCategoryService, $state) => new productCategoryDirective(generalService, $uibModal, productCategoryService, $state);
                directive.$inject = ["generalService", "$uibModal", "productCategoryService", "$state"];
                return directive;
            }
        }

        angular.module("app").directive("gtsProductCategory", productCategoryDirective.factory());
    }
}